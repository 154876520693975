<style>
</style>
<template>

    <div>
        <div class="center grid" >
            <div v-if="loading" >
                <div class="text-center mt-5">
                    <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
                </div>
            </div>
            
                <table class="table table-sm table-hover" ref="tableDataRef" @edit-row="showRow">
                </table>
            
            <div v-if="!loading && tableData.length == 0" class="text-center">
                <strong>No se encontraron datos</strong>
            </div>
        
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt";
import { mapGetters } from 'vuex';

export default{
    name: 'IdemiaTable',
    props:{
        pType: String
    },
    data() {
        return {
            loading: true,
            pageType: this.pType,
            pageTitle: '',
            tableData: [],
            nosearch: false,
            columns: [
                { title: '', data: 'SORT_ROW' },
                { title: 'Fecha Solicitud', data: 'FECHA_SOLICITUD' },
                { title: 'Fecha Enrolamiento', data: 'FECHA_CREACION_IDENTIDIDAD' },
                { title: this.pType == 'Rechazados' ? 'Run Existente' : 'Run Asignado', data: this.pType == 'Rechazados' ? 'RUN_CANDIDATO' : 'RUN' },
                { title: 'Nombres', data: 'FULLNAME' },
                { title: 'Sexo Idemia', data: 'SEX' },
                { title: 'Sexo Solicitud', data: 'SOL_SEXO' },
                { title: 'F. Nac Idemia', data: 'FECHA_NACIMIENTO' },
                { title: 'F. Nac Solicitud', data: 'SOL_FECHA_NACIMIENTO' },
                { title: 'Solicitud', data: 'SOL_ID'},
                {
                    title: 'Ver Detalle',
                    data: null,
                    defaultContent: '<button class="show-button btn btn-primary btn-sm"><i class="fa fa-search"></i></button>'
                },
            ],
            
            columnsOK: [
                { title: '', data: 'SORT_ROW' },
                { title: 'Fecha Solicitud', data: 'FECHA_SOLICITUD' },
                { title: 'Fecha Enrolamiento', data: 'FECHA_CREACION_IDENTIDIDAD' },
                { title:  'Run Asignado', data: 'RUN' },
                { title: 'Nombres', data: 'FULLNAME' },
                //{ title: 'Solicitud', data: 'SOL_ID'},
                {
                    title: 'Ver Detalle',
                    data: null,
                    defaultContent: '<button class="show-button btn btn-primary btn-sm"><i class="fa fa-search"></i></button>'
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['idemiaNotFoundRows', 'idemiaNOKRows', 'idemiaRechazadosRows', 'idemiaSearchResults', 'lastData'])
    },
    methods:{
        showRow(){
            console.log('Show Row');
        }
    },
    watch:{
        idemiaNotFoundRows(newv){
            if(this.pType == 'NotFound'){

                this.loading = false;
                this.tableData = newv;
            }
        },
        idemiaNOKRows(newv){
            this.tableData = newv;
           /*  if(this.pType == 'NOK'){
            } */
            this.loading = false;
        },
        idemiaSearchResults(newv){
            if(this.pType == "search"){
                this.dataTable = newv;
                this.loading = false;
            }
        },
        lastData(newv){
            this.dataTable  = [];
            if(this.pType == "lastData"){
                this.dataTable = newv;
            }
            this.loading = false;
        }
    },
    created(){
        this.columns = this.pType == "search" ? this.columnsOK : this.columns;
        this.loading = true
    },
    mounted(){
        let that = this;
        setTimeout(()=>{
            switch(that.pageType){
                case 'NotFound':
                    that.pageType = ""
                    that.tableData = that.idemiaNotFoundRows;
                    break;
                case 'Rechazados':
                    that.pageType = ""
                    that.tableData = that.idemiaRechazadosRows;
                    break;
                case 'NOK':
                    that.pageType = ""
                    that.tableData = that.idemiaNOKRows;
                    break;
                case 'search':
                    that.nosearch = true;
                    that.pageType = ""
                    that.tableData = that.idemiaSearchResults;
                    break;
                case 'lastData':                
                    that.nosearch = true;
                    that.pageType = ""
                    that.tableData = that.lastData;
                    break;
                default:
                    that.pageType = "";
                    that.tableData = [];
                    break;

            }
            
           
            if(that.tableData.length > 0){
                if(that.tableData[0] == "NO_DATA"){
                    that.tableData = [];
                    that.loading = false;
                } else {

                    const table = $(that.$refs.tableDataRef).DataTable({
                            data: that.tableData,
                            stateSave: true,
                            dom: that.nosearch ? 'lrtip' : 'lfrtip', 
                            language: {
                                lengthMenu: 'Mostrar _MENU_ registros por página',
                                zeroRecords: 'No hay datos para visualizar<br /><a href="javascript:window.location.reload()">Recargar</a>',
                                info: 'Mostrando página _PAGE_ de _PAGES_',
                                infoEmpty: 'No hay Datos para visualizar',
                                infoFiltered: '(filtrado de un total de  _MAX_ registros)',
                                search: 'Buscar: ',
                                
                                "paginate": {
                                    "first":      "Primera",
                                    "last":       "Última",
                                    "next":       "Siguiente",
                                    "previous":   "Anterior"
                                },
                            },
                            columns: that.columns,
                            columnDefs: [
                                {
                                    targets: 0, // the second column index is 1 (zero-based)
                                    className: 'oculta', // add the "text-left" class to the column
                                    visible: false
                                },
                                {
                                    targets: 3, // the second column index is 1 (zero-based)
                                    className: 'text-left', // add the "text-left" class to the column
                                }
                            ],
                            createdRow: function (row, data) {
                
                                $(row).find('.show-button').on('click', () => {
                                    if(data.STATUS == -2 && data.SOL_ID == ''){
                                        that.$router.push('/notfound/' + data.RIN)

                                    } else {
                                        that.$router.push('/detalle/' + data.RIN)
                                    }
                                });
                            },
                            order: [[0, 'desc']]
                
                    });
                    table.draw();
                    let lV = sessionStorage.getItem('l_' + this.pType);
                    let searchEl = document.querySelector('.dataTables_filter input[type=search]');
                    if(searchEl){
                        searchEl.addEventListener('keyup', (evt) => {
                            sessionStorage.setItem('l_' + this.pType, evt.target.value);
                        });
                        searchEl.addEventListener('change', (evt) => {
                            sessionStorage.setItem('l_' + this.pType, evt.target.value);
                        });
                        if(lV){
                            searchEl.value = lV;
                            table.search(lV).draw();
                        }
                    }
                }

            }
            
            that.loading = false;
        },300)

    }
}
</script>