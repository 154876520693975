import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/HomePage.vue'
import Exitosos from './views/ExitososPage.vue'
import NotFound from './views/NotFoundPage.vue'
import NoExitosos from './views/NoExitososPage.vue'
import IdemiaDetails from './components/IdemiaDetails.vue'
import Login from './views/LoginPage.vue'
import Rechazados from './views/RechazadosPage.vue'
import SearchArt44 from './components/SearchArt44.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/exitosos',
    name: 'Exitosos',
    component: Exitosos
  },
  {
    path: '/noexitosos',
    name: 'NoExitosos',
    component: NoExitosos
  },
  {
    path: '/rechazados',
    name: 'Rechazados',
    component: Rechazados
  },
  {
    path: '/notFound',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/detalle/:rin',
    name: 'IdemiaDetails',
    component: IdemiaDetails,
    props: true
  },
  {
    path: '/notfound/:rin',
    name: 'SearchArt44',
    component: SearchArt44,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
