<style>
@import 'assets/bootstrap.min.css';
@import 'datatables.net-dt/css/jquery.dataTables.css';

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table.dataTable thead th {
  text-align: center;
}


.dataTables_filter,
.dataTables_length {
  margin-bottom: 1rem !important;
}

th {
  background: #0077c2 !important;
}

th,
td {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #07c6;
}

th {
  background-color: #0077c2;
  color: white;
}

tr:hover {
  background-color: #c7e3f9;
}

h1 {
  color: #0077c2;
}

blockquote {
  font-style: italic;
}

button.btn-primary {
  background-color: #0077c2;
  border-color: #0077c2;
}

.button {
  background-color: #0077c2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
}

.button:hover {
  background-color: #005ea6;
}



i.fas {
  margin-right: 5px;
  /* Add some margin to the right of the icon */
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 100px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#top {
  top: -60px;
  left: -50%;
}

.hover-text {
  position: relative;
  display: inline-block;
  margin: 40px;
  font-family: Arial;
  text-align: center;
}


@media only screen and (max-width: 767px) {
  * {
    font-size :0.9rem;
  }
}
</style>

<template>
  <div>
    <TopHeader></TopHeader>
  </div> 
  <div class="p-3">
    <router-view></router-view>
  </div>
  
</template>

<script>

//import HelloWorld from './components/HelloWorld.vue'd
import TopHeader from './components/TopHeader.vue';

import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    TopHeader
  },
  computed: {
    ...mapGetters(['loggedIn'])
  },
  methods: {
    ...mapActions([ 'updateTimeline'])
  },
  created() {

    try{
      let jwt = sessionStorage.getItem("JWT");
      if(jwt){
        JSON.parse(atob(jwt.split(".")[1]));
      }

    } catch(ex){
      console.log(ex);
    }


    if (!this.loggedIn) {
      this.$router.push("/login")
    }



  },
  mounted() {

  }

}
</script>

