<style>
    .sol44{
        font-size: 0.9rem;
    }
    .sol44 .row p{
        border: solid 1px #aaa;
        min-height: calc(1rem + 10px);
        line-height: 1rem;
        padding:5px;
        background: #fffa;
        border-radius: 5px;
        box-shadow: 1px 1px 6px #bbb;
    }
    
    .sol44.editMode .row p{
        background: #fffa;
        color:#000;
        min-height: calc(1rem + 21px);
        line-height: 1.5rem;
        border-color: #ccc;
    }
    .sol44 .card-body{
        padding:0;
    }
    .sol44 span h6{
        font-size: 0.9rem;
        font-weight: bold;
        color:#666;
    }

    .sol44 h6{
        font-weight: bold;
        color: #000;
    }
    .sol44 .nombres{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:1rem;
    }
    div.confirm,
    div.solIsValid{
        opacity: 0;
        position: fixed;
        top: 10%;
        left:0;
        margin:0 25%;
        width: 50%;
        z-index: 1000;
        transition: all .3s;
    }
    div.confirm.show,
    div.solIsValid.show{
        opacity: 1;
        transition: all 1s;
    }
    div.solIsValid ul li{
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap:1rem;
    }
    
    div.solIsValid ul li.error{
        background: #faac;
    }
</style>
<style>
  .overlay{
      position: fixed !important;
      z-index: 100;
      top:0;
      left: 0;
      width: 100%;
      height:100vh;
      background:#0006;
  }
</style>
<template>
    <div class="overlay" v-if="confirm"></div>
    <h5 class="text-center">Solicitud en Portal Art44 N°<strong>{{ solid }}</strong></h5>
    <div v-if="idemiaDetails[0].STATUS != 1">
        <div v-if="!editMode && !loading " class="text-left">
            <button class="btn btn-primary btn-sm" @click="toggleEditMode()">Editar</button>
        </div>
        <div v-else-if="!loading" class="text-right">
            <button class="btn btn-success btn-sm" @click="saveChanges()">Guardar</button>
            &nbsp; 
            <a href="javascript:;" @click="toggleEditMode()">Cancelar</a>
        </div>
    </div>

    <div class="sol44 text-left mt-2" :class="editMode ? 'editMode' : ''"  v-if="cbRegiones.length > 0">
        
        <div class="card  alert alert-light mt-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 mb-1">
                        <div v-if="editMode && tram">
                            <div class="nombres">
                                <span>
                                    <h6>Nombres</h6>
                                    <input class="form-control" type="text" v-model="tram.nombre" />
                                </span>
                                <span>
                                    <h6>Primer Apellido</h6>
                                    <input class="form-control" type="text" v-model="tram.paterno" />
                                </span>
                                <span>
                                    <h6>Segundo Apellido</h6>
                                    <input class="form-control" type="text" v-model="tram.materno" />
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <h6>Nombres</h6>
                            <p  class="text-muted"> {{ sol.titulo }}</p>
                        </div>
                        
                    </div>
                    <div class="col-6 mb-1">
                        <h6>Sexo</h6>
                        <div v-if="editMode && tram">
                            <select class="form-control" v-model="tram.sexo">
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                                <option value="I">Indeterminado</option>
                            </select>
                        </div>
                        <p v-else class="text-muted">{{ tram.sexo == 'M' ? 'Masculino' : (tram.sexo == 'F' ? 'Femenino' : tram.sexo) }}</p>
                    </div>
                    <div class="col-6 mb-1">
                        <h6>Fecha Nacimiento</h6>
                        <div v-if="editMode">
                            <input class="form-control" type="text" v-model="tram.fecha_nac" />
                        </div>
                        <p v-else class="text-muted">{{ tram.fecha_nac }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6 mb-1">
                        <h6>País Nacionalidad</h6>
                        <div v-if="editMode && tram">
                            <input class="form-control" type="text" v-model="tram.nacionalidad" />
                        </div>
                        <p v-else class="text-muted"> {{ tram.nacionalidad }}</p>
                    </div>
                    <div class="col-6 mb-1">
                        <h6>DNI</h6>
                        <div v-if="editMode">
                            <input class="form-control" type="text" v-model="tram.pasaporte" />
                        </div>
                        <p v-else class="text-muted">&nbsp; {{ tram.pasaporte }}</p>
                    </div>
                    <div class="col-5 mb-1">
                        <h6>Teléfono</h6>
                        <div v-if="editMode">
                            <input class="form-control" type="text" v-model="tram.telefono" />
                        </div>
                        <p v-else  class="text-muted">{{ tram.telefono }}</p>
                    </div>
                    <div class="col-7 mb-1">
                        <h6>Email</h6>
                        <div v-if="editMode">
                            <input class="form-control" type="text" v-model="tram.email" />
                        </div>
                        <p v-else class="text-muted">{{ tram.email }}</p>
                    </div>
                </div>
            
                <div class="row">
                    <div class="col-12 mb-1">
                        <h6>Dirección</h6>
                        <div v-if="editMode">
                            <input class="form-control" type="text" v-model="tram.direccion" />
                        </div>
                        <p v-else  class="text-muted"> {{ tram.direccion }}</p>
                    </div>
                    <div class="col-6 mb-1">
                        <h6>Región</h6>
                        <div v-if="editMode && tram">
                            <select class="form-control" v-model="tram.region">
                                <option value="-1">Selecciones Región</option>
                                <option v-for="r in config.regiones" :key="r.codigo_region" :value="r.codigo_region" >{{ r.nombre_region }}</option>
                            </select>
                        </div>
                        <p v-else class="text-muted">{{ tram.region == -1 ? 'SIN DATOS' : config.regiones.find(r  => r.codigo_region == tram.region).nombre_region }}</p>
                    </div>
                    <div class="col-6 mb-1">
                        <h6>Comuna</h6>
                        <div v-if="editMode">
                            <select class="form-control" v-model="tram.comuna">
                                <option value="-1">Seleccione Comuna</option>
                                <option v-for="c in cbComunas" :key="c.codigo_comuna" :value="c.codigo_comuna">{{ c.nombre_comuna  }}</option>
                            </select>
                        </div>
                        <p v-else class="text-muted">{{ cbComunas.find(c => c.codigo_comuna == tram.comuna).nombre_comuna }}</p>
                    </div>
                </div>

                <div class="row">    
                    <div class="col-12 mb-1">
                        <h6>Progenitor 1</h6>
                        <div v-if="editMode">
                            <div class="nombres">
                                <span>
                                    <h6>Nombres</h6>
                                    <input class="form-control" type="text" v-model="tram.nombre_progenitor_1" />
                                </span>
                                <span>
                                    <h6>Primer Apellido</h6>
                                    <input class="form-control" type="text" v-model="tram.primer_apellido_progenitor_1" />
                                </span>
                                <span>
                                    <h6>Segundo Apellido</h6>
                                    <input class="form-control" type="text" v-model="tram.segundo_apellido_progenitor_1" />
                                </span>
                            </div>
                        </div>
                        <p v-else class="text-muted"> {{ tram.nombre_progenitor_1 + " " + tram.primer_apellido_progenitor_1 + " " + tram.segundo_apellido_progenitor_1 }}</p>
                    </div>
                    <div class="col-12 mb-1">
                        <h6>Progenitor 2</h6>
                        <div v-if="editMode">
                            <div class="nombres">
                                <span>
                                    <h6>Nombres</h6>
                                    <input class="form-control" type="text" v-model="tram.nombre_progenitor_2" />
                                </span>
                                <span>
                                    <h6>Primer Apellido</h6>
                                    <input class="form-control" type="text" v-model="tram.primer_apellido_progenitor_2" />
                                </span>
                                <span>
                                    <h6>Segundo Apellido</h6>
                                    <input class="form-control" type="text" v-model="tram.segundo_apellido_progenitor_2" />
                                </span>
                            </div>
                        </div>
                        <p v-else class="text-muted"> {{ tram.nombre_progenitor_2 + " " + tram.primer_apellido_progenitor_2 + " " + tram.segundo_apellido_progenitor_2 }}</p>
                    </div>
                
                </div>

            </div>
        </div>
    </div>
    <div v-if="loading" >
        <div class="text-center mt-5">
            <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
        </div>
    </div>
    <div v-if="confirm && solIsValid">    
        <div class="confirm" :class=" 'show' " >
            <div class="card pt-5 px-5 pb-3">
                <div class="card-head">
                    <h2>Actualización de Datos</h2>
                </div>
                <div  v-if="!updateOk">
                    <div class="card-body">
                        Esta acción actualizará los datos en la solicitud y modificará el registro enviado por IDEMIA para ejecutar la actualización en TANDEM.<br /><br />
                        ¿Desea continuar?
                    </div>
                    <div class="footer">
                        <button class="btn btn-ligth btn-sm" @click="cancelUpdate()">Cancelar</button>
                        <button class="btn btn-primary" @click="updateSol()">Actualizar</button>
                    </div>
                </div>
                <div v-else>
                    <div class="card-body">
                        Solicitud Actualizada
                    </div>
                    <div class="footer">
                        <button class="btn btn-primary btn-sm" @click="refreshSol()">OK</button>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!solIsValid" >
        <div class="solIsValid" :class="'show'">
            <div class="card pt-5 px-5 pb-3">
                <div class="card-head">
                    <h2>Error en Solicitud</h2>
                </div>
                <div class="card-body">
                    Revise que la soliictud tenga al menos los siguientes campos con valores válidos:
                    <ul class="list-group text-left">
                        <li class="list-group-item" :class="tram.nombre.trim() == '' ? 'error' : ''">
                            <strong>Nombres</strong> {{ tram.nombre.trim() }}
                        </li>
                        <li class="list-group-item" :class="tram.paterno.trim() == '' ? 'error' : ''"> 
                            <strong>Primer Apellido</strong> {{ tram.paterno.trim() }}
                        </li>
                        <li class="list-group-item" :class="tram.materno.trim() == '' ? 'error' : ''"> 
                            <strong>Segundo Apellido</strong> {{ tram.materno.trim()}}
                        </li>
                        <li class="list-group-item" :class="tram.fecha_nac.trim() == '' ? 'error' : ''">  
                            <strong>Fecha Nacimiento</strong> {{ tram.fecha_nac}}
                        </li>
                        <li class="list-group-item" :class="tram.nacionalidad.trim() == '' ? 'error' : ''">     
                            <strong>País Nacionalidad</strong> {{ tram.nacionalidad}}
                        </li>
                        <li class="list-group-item"  :class="tram.region.trim() == '' || tram.region == '-1' ? 'error' : ''">  
                            <strong>Región</strong> {{ tram.region}}
                        </li>
                        <li class="list-group-item"  :class="tram.comuna.trim() == '' || tram.comuna == '-1' ? 'error' : ''"> 
                            <strong>Comuna</strong> {{ tram.comuna}}
                        </li>
                    </ul>
                </div>
                <div class="footer text-center">
                    <button class="btn btn-primary" @click="cancelUpdate()">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


import { mapActions, mapGetters } from 'vuex';

export default{
    name: 'SolicitudArt44',
    props:{
        solid: String,
        rin: String
    },
    data(){
        return {
            loading: true,
            overlay: false,
            editMode: false,
            confirm: false,
            solIsValid: true,
            sol: {},
            tram: {},
            cbRegion: -1,
            cbComuna: -1,
            cbRegiones: [],
            cbComunas: [],
            updateOk: false
        }
    },
    watch:{
        cbRegion(newv){ 
            this.cbComunas = this.config.regiones.find(r => r.codigo_region == newv).comunas;
        }
    },
    computed:{
        ...mapGetters(['sol44Details', 'config', 'idemiaDetails']),
        fullName(){
           return (this.tram.nombre + " " + this.tram.paterno + " " + this.tram.materno).trim();
        }
    },
    methods:{
        ...mapActions(['getSol44', 'getConfig', 'updateSolicitud']),
        toggleEditMode(){
            this.editMode = !this.editMode;
        },
        updateSol(){
            this.solIsValid = this.validaDatosSol();
            if(this.solIsValid){
                this.updateSolicitud({rin: this.rin, sol: this.solid, direction:'sol', tramite: this.tram}).then(()=> {
                    this.updateOk = true;
                    
                })
            } else {
                console.log("Hay campos en blanco");
            }
        },
        cancelUpdate(){
            this.confirm = false;
            this.solIsValid = true;
        },
        saveChanges(){
            console.log(this.tram);
            this.sol.titulo = this.fullName;
            this.confirm = true;
        },
        checkFields(sol, fields){
            fields.forEach(f => {
                console.log(f, sol[f]);
            })
            return fields.some(attr => sol[attr] === '' || sol[attr] == -1);
        },
        validaDatosSol(){
           return !this.checkFields(this.tram, ['nombre', 'paterno', 'materno', 'fecha_nac', 'nacionalidad', 'comuna', 'region'])
        
        },
        refreshSol(){
            this.$router.go(0)
        }
    },
    created() {
        this.getConfig().then(()=> {
            this.getSol44(this.solid).then( (resData) => {
                this.cbRegiones = this.config.regiones;
                this.tram = resData[0]; // this.sol44Details;
                this.sol = resData[0];  //this.tram.solicitud;
                
                this.cbRegion = this.tram.region;
                this.cbComuna = this.tram.comuna;
                
                this.loading = false;
                console.log(this.idemiaDetails)
            });
        })
    },
    mounted(){
    }
}

</script>