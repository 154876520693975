import { createApp } from 'vue'
import App from './App.vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import router from './router'
import store from './store'
// import Vue from 'vue'
// import { BootstrapVue } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
createApp(App).use(router).use(store).use(HighchartsVue, { highcharts: Highcharts }).mount('#app');
// Vue.use(BootstrapVue)
