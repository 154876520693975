
<style scoped>
  .customDateContainer{
    display: grid;
    grid-template-columns: 4fr 1fr 3fr;
    align-items: center;
  }
  .customDateContainer .picker{
    border:none;
    max-width: 1.3rem;
    color: #fff;
  }
</style>
<template>
  <div class="mx-3 my-2">
    <div class="row">
      <div class="col-9 text-left">
        <h1>Búsqueda de Enrolamientos Artículo 44</h1>
        <div class="row">
          <div class="col-md-6">

            <input type="search" v-model="searchRUN" @keyup="searchIdemia($event)"  @change="searchIdemia($event)" class="form-control form-search " placeholder="Buscar por RUN o Nombre">
          </div>

        </div>
      </div>
      <div class="col-3">
        <strong>Mostrando: {{ customDate }}</strong>
        <ViewDates></ViewDates>
      </div>
      <div v-if="!loading">
        <div v-if="data.length > 0">
          <h2 class="text-left my-2">Resultados de su búsqueda para {{ searchRUN }}</h2>
          <IdemiaTable  pType="search"></IdemiaTable>
        </div>
        <div v-else>
          <div v-if="lastData.length > 0">
            <IdemiaTable :pType="pageTypeLastData"></IdemiaTable>
          </div>
          <div class="text-center mt-5" v-else>
            <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
        </div>
        </div>

      </div>
    </div>
  </div>
</template> 

<script>
import { mapActions, mapGetters } from 'vuex';
import IdemiaTable from '@/components/IdemiaTable.vue';
import ViewDates from '@/components/ViewDates.vue';
export default {
  data(){
    return {
      loading: true,
      pageTypeLastData: 'lastData',
      searchRUN : '',
      data: [],
      typing: null,
      customDate: '',
    }
  },
  components: {
    IdemiaTable,
    ViewDates
  },
  computed: {
    ...mapGetters(['idemiaSearchResults', 'lastSearch', 'lastData', 'searchScope'])
  },
  watch: {
    searchRUN(nv){
      if(nv == ''){
        this.showLastData();
      }
    },
    lastData(newv){
      if(newv[0]){

        this.customDate = newv[0].FECHA_CREACION_IDENTIDIDAD;
        
      }
    },
    idemiaSearchResults(newv){
      this.data = newv
    }
  },
  methods: {
    ...mapActions(['details', 'searchSolicitud', 'clearSearch', 'setLastSearch', 'getDateData']),
    searchIdemia(evt = null){
      if(this.typing){
        clearTimeout(this.typing);
      }
      this.typing = setTimeout(() =>{
        if(evt){
          evt.stopPropagation();
          evt.preventDefault();
        }
        if(this.searchRUN && this.searchRUN.trim() != '' && this.searchRUN.length >= 3){
          this.data = [];
          this.searchSolicitud(this.searchRUN).then( () => {
            this.data = this.idemiaSearchResults;
            this.loading = false;
            this.setLastSearch(this.searchRUN);
          })
        } else {
          this.searchRUN = "";
          this.clearSearch();
          this.data = []
        }
      }, 500)
    },
    showCustomDate(evt){
      if(evt){
        evt.preventDefault();
        evt.stopPropagation();
        let f = evt.target.value.split("-");
        f = f[2] + "-" + f[1] + "-" + f[0];
        this.customDate = f;
      } else {
        this.customDate = 'now';
      }
      this.showLastData();
      this.getDateData(this.customDate)
    },
    showLastData(){
      this.data = []
    }
  },
  created() {
    this.clearSearch();
    this.searchRUN = this.lastSearch || '';
    if(this.searchRUN == ''){
      this.showLastData();
    }
  },
  mounted() {
    this.details(false);
    if(this.searchRUN != ''){
      this.searchIdemia();
    }

    let hoy = new Date();
    hoy.setDate(hoy.getDate() - 1 );
    if(this.lastData[0]){

      let f = this.lastData[0].FECHA_CREACION_IDENTIDIDAD.split("-");
      f = f[1] + "/" + f[0] + "/" + f[2];
      
      f = this.lastData[0].FECHA_CREACION_IDENTIDIDAD;
      this.customDate = f;
      this.searchScope = f;
    } 
  
    this.loading = false;
  }
}

</script>
