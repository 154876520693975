<template>
    <div class="mx-3 my-2 text-left">
        <div class="row">
            <div class="col-md-9">
                <h2><i class="fas fa-exclamation"></i> Errores de Actualización Tandem</h2>
                <blockquote>Registros con problemas al sincronizar con Tandem</blockquote>
            </div>
            <div class="col-md-3 mt-5 text-right">
                <button class="btn btn-primary btn-sm" @click="generateFile()"  v-if="idemiaNOKRows.length > 0">
                <i class="fas fa-file-download"></i> Descargar Archivo
                </button>
            </div>
        </div>
        <div v-if="!loading ">
            <IdemiaTable :pType="pageType" ></IdemiaTable>
        </div>
        
        <div class="text-center mt-5" v-else>
            <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
        </div>
      <!--   <IdemiaNOK></IdemiaNOK> -->
    </div>
</template> 

<script>
import IdemiaTable from '@/components/IdemiaTable.vue';

import xlsx from "xlsx/dist/xlsx.full.min";
import { mapGetters, mapActions } from 'vuex';
export default {
    data(){
        return {
            pageType: 'NOK',
            loading: true
        }
    },
    components: {
        IdemiaTable,
//        IdemiaNOK
    },
    computed: {
        ...mapGetters([  'idemiaNOKRows'])
    },
    methods: {
        ...mapActions(['updateIdemiaNOK', 'details']),
        generateFile() {
            let data = this.idemiaNOKRows;
            
            const XLSX = xlsx;
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet);
            XLSX.writeFile(workbook, "CasosErrorTandem_" + (new Date().valueOf()) + ".xlsx");
        }
    },
    created(){
        
        if(this.idemiaNOKRows.length == 0){
            this.updateIdemiaNOK().then( () => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.details(false);
    
    }
}
</script>