
import Vuex from 'vuex'



export default new Vuex.Store({
  state: {
    API_BACKEND: process.env.VUE_APP_BACKEND,
    API_BACKEND_AUTH: process.env.VUE_APP_BACKEND_AUTH,
    showDetails: false,
    idemiaNotFoundRows: [],
    idemiaNOKRows: [],
    idemiaRechazadosRows: [],
    idemiaDetails: [],
    sol44Details: {},
    notFoundRows: [],
    lastData: [],
    lastSearch: '',
    searchResults: [],
    searchScope: '',
    timeline: JSON.parse(sessionStorage.getItem("timeline")) || [],
    stats: JSON.parse(sessionStorage.getItem("stats")) || {
      ok: 0,
      nok: 0,
      notfound: 0,
      rejected: 0,
      byOrg: []
    },
    lastProcess: {},
    expires: new Date().valueOf(),
    lastUpdate: new Date().valueOf(),
    loggedIn: (sessionStorage.getItem("JWT") && sessionStorage.getItem("JWT") != "") || false,
    JWT: sessionStorage.JWT || '',
    config: {},
    resultsNotFound: []
  },
  getters: {
    getResultsNotFound(store){
      return store.resultsNotFound;
    },
    config(store){
      return store.config;
    },
    searchScope(store){
      return store.searchScope;
    },
    expires(store){
      return store.expires;
    },
    lastProcess(store){
      return store.lastProcess;
    },
    lastData(store){
      return store.lastData;
    },
    lastUpdate(store){
      return store.lastUpdate;
    },
    lastSearch(store){
      return store.lastSearch;
    },
    idemiaDetails(store){
      return store.idemiaDetails
    },
    sol44Details(store){
      return store.sol44Details
    },
    idemiaNotFoundRows(store){
      return store.idemiaNotFoundRows;
    },
    idemiaNOKRows(store){
      return store.idemiaNOKRows;
    },
    idemiaRechazadosRows(store){
      return store.idemiaRechazadosRows;
    },
    loggedIn(store) {
      return store.loggedIn;
    },
    timeline(store) {
      return store.timeline
    },
    showDetails(store) {
      return store.showDetails;
    },
    idemiaSearchResults(store) {
      return store.searchResults;
    },
    stats(store) {
      return store.stats;
    }
  },
  mutations: {
    SET_CONFIG(state, config){
      state.config = config;
    },
    SET_DETAILS(state, show) {
      state.showDetails = show;
    },
    SET_STATS(state, data){
      let statok = data.find(d => d.STATUS == 1);
      let statnok = data.find(d => d.STATUS == -1);
      let statnotf = data.find(d => d.STATUS == -2);
      let statrej = data.find(d => d.STATUS == -3);

      state.stats.ok = statok ? statok.total : 0;
      state.stats.nok = statnok ? statnok.total : 0;
      state.stats.notfound = statnotf ? statnotf.total : 0;
      state.stats.rejected = statrej ? statrej.total : 0;

    
      sessionStorage.setItem("stats", JSON.stringify(state.stats))
    },
    SET_STATS_BY_ORG(state, data){
      let theStats = state.stats;
      theStats.byOrg = data;
      
      state.stats = theStats;
      
      sessionStorage.setItem("stats", JSON.stringify(state.stats))
    },
    SET_IDEMIA_NOTFOUND(state, data){
      state.idemiaNotFoundRows = data;
    },
    SET_IDEMIA_NOK(state, data){
      state.idemiaNOKRows = data;
    },
    SET_IDEMIA_RECHAZADOS(state, data){
      state.idemiaRechazadosRows = data;
    },
    SET_RESULTS(state, results) {
      state.searchResults = results;
    },
    SET_IDEMIA_DETAILS(state, details){
      state.idemiaDetails = details;
    },
    SET_SOL44_DETAILS(state, details){
      state.sol44Details = details;
    },
    SET_TIMELINE(state, data) {
      state.timeline = data;
      sessionStorage.setItem("timeline", JSON.stringify(data));
    },
    SET_LOGIN_DATA(state, loginData) {
     
      state.loggedIn = true;
      state.JWT = loginData.accessToken;
      sessionStorage.setItem("fullName", loginData.fullName)
      sessionStorage.setItem("JWT", state.JWT)
    },
    SET_LAST_DATA(state, data){
      state.lastData = data;
    },
    SET_LAST_PROCESS(state, data){
      state.lastProcess = data;
    },
    SET_LAST_SEARCH(state, run){
      state.lastSearch = run;
      sessionStorage.setItem('ls', run)
    },
    LOGOUT_USER(state){
      state.loggedIn = false;
      state.JWT = '';
      sessionStorage.removeItem("JWT");
      sessionStorage.removeItem("lastPage");
      
      window.location.reload();
    },
    SET_SCOPE_DATE(state, date){
      
      state.searchScope = date;
    },
    SET_RESULTS_NOT_FOUND(state, data){
      state.resultsNotFound = data;
    }
  },
  actions: {
    async searchNotFound(context, searchData){
      context.commit('SET_RESULTS_NOT_FOUND', []);
      let searchURL = context.state.API_BACKEND + '/search-not-found';
       return fetch(searchURL, {
        method:'POST', 
        body: JSON.stringify(searchData),
        headers: {
          "Content-Type": "application/json",
          authorization: 'Bearer ' + context.state.JWT
        }
      }).then(r => r.json()).then((data) => {
        
        context.commit('SET_RESULTS_NOT_FOUND',data );
        return data;

      }).catch((ex) =>{
        context.commit('SET_RESULTS_NOT_FOUND', []);
        console.log(ex);
        return [];
      }) ;
      
    },
    async getConfig(context){
      let urlLastData = context.state.API_BACKEND + '/config';
    
       let respLM = fetch(urlLastData, {headers: {authorization: 'Bearer ' + context.state.JWT}});
       
       respLM.then(r => { 
         if(r.status == 200){
           return r.json()
         } else if(r.status == 401){
           return new Promise((resolve) => {resolve(401)})
         } else {
           return 'error'
         }
       }).then(data => {
         if(data == 401){
           context.commit('LOGOUT_USER');
           
         } else {
          context.commit('SET_CONFIG', data)
         }
        })

    },
    setScopeDate(context, date){
      context.commit('SET_SCOPE_DATE', date);

    },
    details(context, show) {
      context.commit('SET_DETAILS', show);
    },
    async updateSolicitud(context, data){
      let urlUP = context.state.API_BACKEND + '/update';
      
      return fetch(urlUP, {
        method:'POST', 
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: 'Bearer ' + context.state.JWT
        }
      }).then(r => r.json()).then(() => {
        console.log('UPDATE RESPONSE' );
      }).catch((ex) =>{
        console.log(ex);
      })
    },
    async getDateData(context, date){
       //GEAT CUSTOM DATA
       context.commit("SET_LAST_DATA", []);
       let urlLastData = context.state.API_BACKEND + '/lastdata/' + date;
    
       let respLM = fetch(urlLastData, {headers: {authorization: 'Bearer ' + context.state.JWT}});
       
       respLM.then(r => { 
         if(r.status == 200){
           return r.json()
         } else if(r.status == 401){
           return new Promise((resolve) => {resolve(401)})
         } else {
           return 'error'
         }
       }).then(data => {
         if(data == 401){
           context.commit('LOGOUT_USER');
           
         } else {
           data.forEach(item => {
             item.FULLNAME = item.NOMBRES + " " + item.PRIMER_APELLIDO + " " + item.SEGUNDO_APELLIDO;
             item.SEX = item.SEXO.charAt(0);
             item.SORT_ROW = item.FECHA_SOLICITUD.split("-").reverse().join("-")
           });
           data = data.sort((a, b) => a.SORT_ROW > b.SORT_ROW ? -1 : 1);
           data = data.length > 0 ? data : ["NO_DATA"]
           context.commit("SET_LAST_DATA", data);
         }
         
       })
    },
    async getLastProcess(context){
       //GEAT CUSTOM DATA
       context.commit("SET_LAST_PROCESS", []);
       let urlLastData = context.state.API_BACKEND + '/lastprocess';
       
       let respLM = fetch(urlLastData, {headers: {authorization: 'Bearer ' + context.state.JWT}});
       
       respLM.then(r => { 
         if(r.status == 200){
           return r.json()
         } else if(r.status == 401){
           return new Promise((resolve) => {resolve(401)})
         } else {
           return 'error'
         }
       }).then(data => {
         if(data == 401){
           context.commit('LOGOUT_USER');
           
         } else {
          
           context.commit("SET_LAST_PROCESS", data);
           return data;
         }
         
       })
    },
    async updateStats(context){
     // if(context.state.stats.ok == 0){

       
       try{
        //GET STATS
        let urlStats = context.state.API_BACKEND + '/stats';
       let resp = fetch(urlStats, {headers: {authorization: 'Bearer ' + context.state.JWT}});
       
       resp.then(r => { 
         if(r.status == 200){
           return r.json()
         } else if(r.status == 401){
           return new Promise((resolve) => {resolve(401)})
         } else {
           return 'error'
         }
       }).then(data => {
         if(data == 401){
           context.commit('LOGOUT_USER');
           
         } else {
           context.commit("SET_STATS", data);
         }
         
       });

         //GET STATSBY ORG
         let urlStatsByOrg = context.state.API_BACKEND + '/byorg';
        let respByOrg = fetch(urlStatsByOrg, {headers: {authorization: 'Bearer ' + context.state.JWT}});
        
        respByOrg.then(r => { 
          if(r.status == 200){
            return r.json()
          } else if(r.status == 401){
            return new Promise((resolve) => {resolve(401)})
          } else {
            return 'error'
          }
        }).then(data => {
          if(data == 401){
            context.commit('LOGOUT_USER');
            
          } else {
            context.commit("SET_STATS_BY_ORG", data);
          }
          
        });

        //GEAT LAST MONTH 
        let urlLastData = context.state.API_BACKEND + '/lastdata/now';
       
        let respLM = fetch(urlLastData, {headers: {authorization: 'Bearer ' + context.state.JWT}});
        
        respLM.then(r => { 
          if(r.status == 200){
            return r.json()
          } else if(r.status == 401){
            return new Promise((resolve) => {resolve(401)})
          } else {
            return 'error'
          }
        }).then(data => {
          if(data == 401){
            context.commit('LOGOUT_USER');
            
          } else {
            data.forEach(item => {
              item.FULLNAME = item.NOMBRES + " " + item.PRIMER_APELLIDO + " " + item.SEGUNDO_APELLIDO;
              item.SEX = item.SEXO.charAt(0);
              item.SORT_ROW = item.FECHA_CREACION_IDENTIDIDAD.split("-").reverse().join("-")
            });
            data = data.sort((a, b) => a.SORT_ROW > b.SORT_ROW ? -1 : 1);
            context.commit("SET_LAST_DATA", data);
          
          }
          
        })
      } catch(ex){
        console.log('ERROR', ex);
        return false;
      }
      return true;
     /* } else {
        return true;
      }  */
    },
    async updateTimeline(context) {
//        if(context.state.timeline.length == 0){

          let urlOk = context.state.API_BACKEND + '/timeline';
          return fetch(urlOk,  {headers: {authorization: 'Bearer ' + context.state.JWT}}).then(r => r.json()).then(data => {
            let suma = 0;
            data.forEach(d => {
              suma += d.total;
              d.sum = suma;
            });
            context.commit("SET_TIMELINE", data);
          });
/*         } else {
          return true;
        } */
      },
    async getSol44(context, solid) {
      
      context.commit("SET_SOL44_DETAILS", {});
      let urlOk = context.state.API_BACKEND + '/solicitud/' + solid;
      return fetch(urlOk, {headers: {authorization: 'Bearer ' + context.state.JWT}}).then(r => {
        if(r.status == 200){
          return r.json()
        } else if(r.status == 401){
          return new Promise((resolve) => {resolve(401)})
        } else {
          return 'error'
        }
       
      }).then(data => {
        if(data == 401){
          context.commit('LOGOUT_USER');
          
        } else {

          context.commit("SET_SOL44_DETAILS", data);
        }
        
        return data;
        
      }).catch(() => {
        context.commit("SET_SOL44_DETAILS", {});
      })
    },
    async getDetails(context, rin) {
      
      context.commit("SET_IDEMIA_DETAILS", []);
      let urlOk = context.state.API_BACKEND + '/idemiadetails/' + rin;
      return fetch(urlOk, {headers: {authorization: 'Bearer ' + context.state.JWT}}).then(r => {
        if(r.status == 200){
          return r.json()
        } else if(r.status == 401){
          return new Promise((resolve) => {resolve(401)})
        } else {
          return 'error'
        }
      }).then(data => {
        if(data == 401){
          context.commit('LOGOUT_USER');
          
        } else {
          context.commit("SET_IDEMIA_DETAILS", data);
        }
        //return data;
        
      }).catch(() => {
        context.commit("SET_IDEMIA_DETAILS", []);
      })
    },
    async updateIdemiaNOK(context) {
      let urlOk = context.state.API_BACKEND + '/idemianok';
      fetch(urlOk, {headers: {authorization: 'Bearer ' + context.state.JWT}}).then(r => r.json()).then(data => {
        data.forEach(item => {
          item.FULLNAME = item.NOMBRES + " " + item.PRIMER_APELLIDO + " " + item.SEGUNDO_APELLIDO;
          item.SEX = item.SEXO.charAt(0);
          item.SORT_ROW = item.FECHA_SOLICITUD.split("-").reverse().join("-")
          //item.FECHA_CREACION_IDENTIDIDAD = item.FECHA_CREACION_IDENTIDIDAD.split("-").reverse().join("-");

        });
        data = data.sort((a, b) => a.SORT_ROW < b.SORT_ROW ? -1 : 1);
        data.map( i => {
          let s = i;
          
          if(s.SOL_ID != ''){
            i.TIENE_SOLICITUD = "SI";
           
          } else {
            i.TIENE_SOLICITUD = "NO";
           }
        })
        context.commit('SET_IDEMIA_NOK', data);
        

        
      }).catch(() => {
        
        context.commit('SET_IDEMIA_NOK', []);
      })
    },
    async updateIdemiaNotFound(context) {
      let urlOk = context.state.API_BACKEND + '/idemianotfound';
      fetch(urlOk,  {headers: {authorization: 'Bearer ' + context.state.JWT}} ).then(r => r.json()).then(data => {
        data.forEach(item => {
          item.FULLNAME = item.NOMBRES + " " + item.PRIMER_APELLIDO + " " + item.SEGUNDO_APELLIDO;
          item.SEX = item.SEXO.charAt(0);
          item.SORT_ROW = item.FECHA_SOLICITUD.split("-").reverse().join("-")
          //item.FECHA_CREACION_IDENTIDIDAD = item.FECHA_CREACION_IDENTIDIDAD.split("-").reverse().join("-");

        });
        data = data.sort((a, b) => a.SORT_ROW < b.SORT_ROW ? -1 : 1);
        data.map( i => {
          let s = i;
          
          if(s.SOL_ID != ''){
            i.TIENE_SOLICITUD = "SI";
           
          } else {
            i.TIENE_SOLICITUD = "NO";
           }
        })
        context.commit('SET_IDEMIA_NOTFOUND', data);
        
      }).catch(() => {
        
        context.commit('SET_IDEMIA_NOTFOUND', []);
      })
    },
    async updateIdemiaRechazados(context) {
      let urlOk = context.state.API_BACKEND + '/idemiarechazados';
      fetch(urlOk, {headers: {authorization: 'Bearer ' + context.state.JWT}}).then(r => r.json()).then(data => {
        data.forEach(item => {
          item.FULLNAME = item.NOMBRES + " " + item.PRIMER_APELLIDO + " " + item.SEGUNDO_APELLIDO;
          item.SEX = item.SEXO.charAt(0);
          item.SORT_ROW = item.FECHA_SOLICITUD.split("-").reverse().join("-")
          //item.FECHA_CREACION_IDENTIDIDAD = item.FECHA_CREACION_IDENTIDIDAD.split("-").reverse().join("-");

        });
        data = data.sort((a, b) => a.SORT_ROW < b.SORT_ROW ? -1 : 1);
        data.map( i => {
          let s = i;
          
          if(s.SOL_ID != ''){
            i.TIENE_SOLICITUD = "SI";
           
          } else {
            i.TIENE_SOLICITUD = "NO";
           }
        })
        context.commit('SET_IDEMIA_RECHAZADOS', data);
        

        
      }).catch(() => {
        
        context.commit('SET_IDEMIA_RECHAZADOS', []);
      })
    },
    clearSearch(context){
      context.commit("SET_RESULTS", []);
      context.commit("SET_LAST_SEARCH", null);
    },
    setLastSearch(context, run){
      context.commit('SET_LAST_SEARCH', run);
    },
    async searchSolicitud(context, idemiaRUN) {
      context.commit('SET_RESULTS', []);
      let urlOk = context.state.API_BACKEND + '/search';
      return fetch(urlOk, {
        method: 'POST',
        body: JSON.stringify({idemiaRUN:idemiaRUN}),
        headers: {
          "Content-Type": "application/json",
          "authorization": 'Bearer ' + context.state.JWT
        }
      }).then(r => r.text()).then(data => {
        let results = JSON.parse(data);
        results.map(item => {
          item.FULLNAME = item.NOMBRES + " " + item.PRIMER_APELLIDO + " " + item.SEGUNDO_APELLIDO;
          item.SEX = item.SEXO.charAt(0);
          item.SORT_ROW = item.FECHA_CREACION_IDENTIDIDAD.split("-").reverse().join("-")
        })
        //   results = results.sort( (a, b) => a.avg < b.avg ? 1 : -1)
        context.commit('SET_RESULTS', results);
        return results;
      }).catch((err) => {
        console.log(err);
        context.commit('SET_RESULTS', []);
      });

    },
    async loginUser(context, loginData) {
      /**
       * 1.- Consumir endpoint de auth
       * 2.- Guardar Datos de Usuarioy JWT
       */
      let urlLogin =  context.state.API_BACKEND_AUTH + '/login';
      
      return fetch(urlLogin, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData)
      }).then(r => r.text()).then(dataRes => {
        
        let results = JSON.parse(dataRes);
/* 
        console.log(results);
        let jwt = results.accessToken; */
        let payload = results;
        if (payload.rolCodigo == 0 || payload.rolCodigo == 50) {
          delete results.opt;
          delete results.userStatus;
          delete results.region;
          results["fullName"] = results.firstName + " " + results.lastName;
          context.commit("SET_LOGIN_DATA", results);

          
          return true

        } else {
          sessionStorage.removeItem("JWT");
          context.commit("LOGOUT_USER");
          return false

        }

      }).catch((err) => {
        console.log(err);
        return false
      });

    },
    logout(context){
      context.commit('LOGOUT_USER')
    },
    async getReporteSemanal(context){
      let urlReport = context.state.API_BACKEND+ '/reportesemanal';
      
      return fetch(urlReport, {headers: {authorization: 'Bearer ' + context.state.JWT}})
      .then(r => r.text()).then(data => {
                 
          return data;

        
      }).catch((err) => {
        console.log(err);
        return err
      });
    }
  }
})
