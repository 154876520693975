<style scoped>
        .calendar {
            text-align: center;
            font-family: Arial, sans-serif;
            font-size:10px;
            border:solid 1px #ccc;
            max-width: 220px;
            margin: 0 auto;
        }

        .header {
            display:grid;
            grid-template-columns: 1fr 8fr 1fr;
            font-weight: bold;
            margin-bottom: 5px;
            padding: 2px;
        }

        .navigation {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }

        .prev,
        .next {
            background:#333;
            color:#fff;
            cursor: pointer;
            font-weight: bold;
            border:solid 1px #aaa;
            border-radius:50%;
        }

        .days {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            color: #999;
        }

        .day {
            padding: 4px;
   
        }
        #daysLabels{
          background:#adf;
          color:#000;
        } 
</style>
<style>
        .days a{
            text-decoration: none;
            color:#000;
            font-weight:bold;

        }
</style>
<template>
    <div class="text-center">
        <div class="calendar">
            <div class="header">
                <span id="prev" class="prev" @click="prevMonth()">&lt;</span>
                <span id="currentMonth"></span>
                <span id="next" class="next" @click="nextMonth()">&gt;</span>
            </div>
            <div  class="days" id="daysLabels">
                <div class="day">D</div>
                <div class="day">L</div>
                <div class="day">M</div>
                <div class="day">M</div>
                <div class="day">J</div>
                <div class="day">V</div>
                <div class="day">S</div>
            </div>
            <div class="days" id="calendarDays"></div>
        </div>
        <div class="shortcuts">
            <a href="javascript:;" @click="getDateData('now')">Limpiar Filtro de Fecha</a>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';
export default{
    name: 'ViewDates',
    data() {
        return {
            hoy: new Date(),
            currentDate: null,
            calendarDays: null,
            currentMonth: null,
            numDays: null,
            firstDay: null,

        }
    },
   
    methods: {
        ...mapActions(['getDateData', 'setScopeDate']),
        renderCalendar() {
            const calendarDays = document.getElementById('calendarDays');
            const currentMonth = document.getElementById('currentMonth');

            // Clear previous month's days
            calendarDays.innerHTML = '';

            // Set current month and year
            currentMonth.textContent = this.currentDate.toLocaleString('es-CL', { month: 'long' }) + ' ' + this.currentDate.getFullYear();
            this.currentMonth = this.currentDate.getMonth() + 1;
            // Get the number of days in the current month
            const numDays = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0).getDate();

            // Get the first day of the month
            const firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1).getDay();

            // Render days
            for (let i = 0; i < firstDay; i++) {
                const emptyDay = document.createElement('div');
                emptyDay.classList.add('day');
                calendarDays.appendChild(emptyDay);
            }

            for (let i = 1; i <= numDays; i++) {
                const day = document.createElement('div');
                day.classList.add('day');
                if(this.currentDate <= this.hoy ){
                    if(this.currentDate.getMonth() == this.hoy.getMonth()){
                        if(i < this.currentDate.getDate()){
                            const a = document.createElement('a');
                            a.setAttribute('href', 'javascript:;');
                            a.setAttribute('onclick', 'vShowDay(' + i + ')');
                            a.textContent = i;
                            
                            day.appendChild(a)
                            calendarDays.appendChild(day);
                        } else {
                            day.textContent = i;
                            calendarDays.appendChild(day);
                        }
                    } else if(this.currentDate.getMonth() < this.hoy.getMonth()){
                        const a = document.createElement('a');
                        a.setAttribute('href', 'javascript:;');
                        a.setAttribute('onclick', 'vShowDay(' + i + ')');
                        a.textContent = i;
                       
                        day.appendChild(a)
                        calendarDays.appendChild(day);
                    }
                }
            }
        },
        nextMonth(){
            if(this.currentDate.getMonth() < this.hoy.getMonth()){
                this.currentDate.setMonth(this.currentDate.getMonth() + 1);

                this.renderCalendar();
            }
        },
        prevMonth(){
            this.currentDate.setMonth(this.currentDate.getMonth() - 1);
            this.renderCalendar();
        },

        showDay(d) {
            let f = this.currentDate.toJSON().split("T")[0].split("-").reverse()
            d = d < 10 ? '0' + d : d;
         
            f[0] = d;
            f = f.join("-");
            this.setScopeDate(f);
           this.getDateData(f);
        }
    },
    mounted() {
        this.hoy.setHours(this.hoy.getHours()  + 1)
        window.vShowDay = (d) => {
            this.showDay(d)
        }


        // Initialize current date
        this.currentDate = new Date();

        // Render the calendar
      

        // Event listeners for navigation
       /*  document.getElementById('prev').addEventListener('click', function () {
            
        }); */

        /* document.getElementById('next').addEventListener('click', function () {
            
        }); */

        // Initial render
        this.renderCalendar();
    }
}

</script>

