<style scoped>
.circle{
    aspect-ratio: 1;
    padding:1rem 1rem 0 1rem;
    width: 180px;
    font-size: 0.9rem;
}
.circle h3{
    font-size: 2rem;
    line-height: 2rem;
    color: #aaa;
}
.logout{
    background-color: #fff;
    color:#aaa;
    border:solid 1px #aaa;
    padding:0;
    border-radius: 8px;
    margin-top: 0.4rem;
    
}
div.pin{
    border:solid 1px #aaa;
    width:1rem;
    height: 1rem;
    margin: 0 auto;
    margin-top: -0.5rem;
    transform: rotate(45deg);
    border-bottom: none;
    border-right: none;
    background: #fff;
}
.logout a{
    display: inline-block;
    text-decoration: none;
    color:#666;
    padding:0.5rem 0.3rem;
    
}

.logout a:hover{
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    
    .circle{
        font-size: 0.8rem;
        padding:0.5rem 0;
    }
    .circle strong{
        display:none;
    }
}
</style>
<template>
    <div>
        <div class="circle" >
            <h3 @click="toggler()"><i class="fa fa-user"></i></h3>
            <strong>{{ userName }}</strong>
            <div class="logout" v-if="showLogout">
                <div class="pin"></div>
                <a href="javascript:;" @click="logoutUser()">
                    <i class="fa fa-lock"></i> <small> Cerrar Sesión</small>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            userName: sessionStorage.getItem("fullName"),
            showLogout: false
        }
    },
    computed:{
        ...mapGetters(['loggedIn'])
    },
    methods: {
        ...mapActions(["logout"]),
        toggler(){
            this.showLogout = !this.showLogout;
        },
        logoutUser() {
            this.logout();
            this.$router.push("/login");
        }
    },
    mounted(){
    }
}

</script>