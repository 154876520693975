<style scoped>
    .comparador {
        margin:3rem;
        margin-top:2rem;
    }

    .comparador h4{
        font-size:95%;
        font-weight: bold;
        padding:0.5rem 1rem;
        border-radius: 4px;
    }
    .errorNotFound *{
        border-color:red;
        color:red;
    }
    .dataWarning * {
        border-color:rgb(199, 196, 4);
        color:rgb(199, 196, 4);
    }
    .dataFound * {
        border-color:green;
        color:green;
    }

    /* temporal mienstras actualizado */
    .arrows{
        padding:1rem 0;
    } 
    .arrows button.btn{
        display:block;
        width:100%;
        margin:5px 0;
        padding:1px 1.3rem;
    } 

    .col-md-2.text-center a:hover{
        padding:0.5rem 0;
        zoom:200%;
        overflow:hidden;
        transition: all 0.9;
    }

    .comparador .row{
        border-bottom:solid 1px #aaa;
        line-height: 3rem;
    }

    .card-body.alert{
        margin:0;
    }
</style>
<template>
    <div class=" rounded bg-white mt-1">
        <div class="text-left">
            <button class="btn btn-secondary btn-small profile-button ml-1" @click="goBack()">
                <i class="fa fa-reply" aria-hidden="true"></i> Volver
            </button>
        </div>
        <div class="row">
          
            <!-- DETALLE DE REGISTROS EXITOSOS -->
            <div class="col-md-6 offset-3 border-right isOK mt-4 pt-2" v-if="solicitud.STATUS == 1">
                <div class="card alert alert-success p-3 py-3">
                    <div class="m-auto mb-4">
                        <h2 class="text-left">RUN: {{ solicitud.RUN }}</h2>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-4"><label class="labels"><strong>Nombres</strong></label>
                            <input disabled type="text" class="form-control text-center bg-white"
                                v-model="solicitud.NOMBRES" />
                        </div>
                        <div class="col-md-4"><label class="labels"><strong>Primer apellido</strong></label>
                            <input disabled type="text" class="form-control text-center bg-white"
                                v-model="solicitud.PRIMER_APELLIDO" />
                        </div>
                        <div class="col-md-4"><label class="labels"><strong>Segundo apellido</strong></label>
                            <input disabled type="text" class="form-control text-center bg-white"
                                v-model="solicitud.SEGUNDO_APELLIDO" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6"><label class="labels"><strong>Fecha de nacimiento</strong>

                            </label>
                            <label type="text" class="form-control">
                                {{ solicitud.FECHA_NACIMIENTO }}
                            </label>
                        </div>
                        <div class="col-md-6"><label class="labels"><strong>Sexo</strong></label>
                            <label type="text" class="form-control">
                                {{ solicitud.SEXO }}
                            </label>

                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6"><label class="labels"><strong>Fecha de enrolamiento </strong></label>
                            <label type="text" class="form-control">
                                {{ solicitud.FECHA_CREACION_IDENTIDIDAD }}
                            </label>
                        </div>
                        <div class="col-md-6"><label class="labels"><strong>Estado</strong></label>
                            <label type="text" class="form-control">
                                Actualizado en Tandem
                            </label>
                        </div>

                    </div>
                    <div class="row mt-3" v-if="solicitud.RESPONSE_TANDEM.codigo">
                        <div class="col-md-12"><label class="labels"><strong>Error</strong></label>
                            <label type="text" class="form-control">
                                {{ solicitud.RESPONSE_TANDEM.codigo + ": " + solicitud.RESPONSE_TANDEM.glosa }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- DETALLE DE REGISTROS CON PROBLEMAS DE SINCRONIZACIÓN TANDE,M -->
            <div class="col-6 border-right isNotOK" v-if="solicitud && (solicitud.STATUS == -1 || solicitud.STATUS == -3)">
                <RegistroIdemia :rin="idemiaRIN"></RegistroIdemia>
               
            </div>

            <!-- DETALLE DE REGISTROS CON SOLICITUD NO ENCONTRADA -->
            <div class="col-md-6 border-right isNotFound" v-if="solicitud && solicitud.STATUS == -2">
        
                <div class="m-1 mt-4">
                    <h2 class="text-left">RUN: {{ solicitud.RUN }}</h2>
                </div>
                <div class="row my-3">
                    <div class="col-md-6"><label class="labels"><strong>Fecha de enrolamiento </strong></label>
                        <label type="text" class="form-control">
                            {{ solicitud ? solicitud.FECHA_CREACION_IDENTIDIDAD : '' }}
                        </label>
                    </div>
                    <div class="col-md-6" ><label class="labels"><strong>Estado</strong></label>
                        {{ solicitudOK }}
                        <div v-bind:class="solicitudOK ? 'dataFound' : (isNaN(solicitud.SOL_ID * 1) || solicitud.SOL_ID == '' ? 'errorNotFound' : 'dataWarning')">

                            <label type="text" class="form-control" v-if="solicitudOK">
                                SOLICITUD CORREGIDA
                            </label>
                            <label v-else>
                                {{ isNaN(solicitud.SOL_ID) || solicitud.SOL_ID == '' ?  'NO SE ENCONTRÓ SOLICITUD' : 'MATCH PARCIAL DE DATOS'  }}
                            </label>
                        </div>
                    </div>

                </div>
                <div class="m-3">
                    <h3>Comparación de Datos</h3>
                </div>
                <div class=" mt-2 comparador">
                    <div class="row">
                        <div class="col-md-5 text-center"><h4 class="alert alert-danger">DATOS IDEMIA</h4></div>
                        <div class="col-md-2 text-center arrows">
                        
                        </div>
                        <div class="col-md-5 text-center"><h4 class="alert alert-success">DATOS SOLICITUD</h4></div>
                    </div>
                    <div class="row">
                        <div class="col-md-5"><label class="labels"><strong>Nombres</strong></label>
                            <input disabled="disabled" type="text" class="form-control text-center bg-white"
                                v-model="solicitud.NOMBRES" />
                        </div>
                        <div class="col-md-2 text-center arrows">
                            <div v-if="solicitud.SOL_ID != '' && solicitud.SOL_NOMBRES.trim() != solicitud.NOMBRES.trim()">
                                <button class="btn btn-danger" @click="i2s($event, solicitud.RIN, 'NOMBRES')">
                                    <i class="fa fa-arrow-right"></i>
                                </button>
                                
                                <button class="btn btn-success" @click="s2i($event, solicitud.RIN, 'NOMBRES')">
                                    <i class="fa fa-arrow-left"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div v-if="solicitud.SOL_ID != '' ">
                                <label class="labels"><strong>Nombres</strong></label>
                                <div v-bind:class="solicitud.SOL_NOMBRES.trim() != solicitud.NOMBRES ? 'errorNotFound' : 'dataFound'">
                                    <input type="text" class="form-control disabled text-center" v-model="solicitud.SOL_NOMBRES">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5"><label class="labels"><strong>Primer apellido</strong></label>
                            <input disabled="disabled"  type="text" class="form-control text-center bg-white"
                                v-model="solicitud.PRIMER_APELLIDO" />
                        </div>
                        <div class="col-md-2 text-center arrows">
                            <div v-if="solicitud.SOL_ID != ''  && solicitud.SOL_PRIMER_APELLIDO.trim() != solicitud.PRIMER_APELLIDO.trim()">
                                <button class="btn btn-danger" @click="i2s($event, solicitud.RIN, 'PRIMER_APELLIDO')">
                                    <i class="fa fa-arrow-right"></i>
                                </button>
                                
                                <button class="btn btn-success" @click="s2i($event, solicitud.RIN, 'PRIMER_APELLIDO')">
                                    <i class="fa fa-arrow-left"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div v-if="solicitud.SOL_ID != '' ">
                                <label class="labels"><strong>Primer apellido</strong></label>
                                <div v-bind:class="solicitud.SOL_PRIMER_APELLIDO.trim() != solicitud.PRIMER_APELLIDO ? 'errorNotFound' : 'dataFound'">
                                    <input type="text" class="form-control disabled text-center" v-model="solicitud.SOL_PRIMER_APELLIDO">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5"><label class="labels"><strong>Segundo apellido</strong></label>
                            <input disabled="disabled"  type="text" class="form-control text-center bg-white"
                                v-model="solicitud.SEGUNDO_APELLIDO" />
                        </div>
                        <div class="col-md-2 text-center arrows">
                            <div v-if="solicitud.SOL_ID != ''  && solicitud.SOL_SEGUNDO_APELLIDO.trim() != solicitud.SEGUNDO_APELLIDO.trim()">
                                <button class="btn btn-danger" @click="i2s($event, solicitud.RIN, 'SEGUNDO_APELLIDO')">
                                    <i class="fa fa-arrow-right"></i>
                                </button>
                                <button class="btn btn-success" @click="s2i($event, solicitud.RIN, 'SEGUNDO_APELLIDO')">
                                    <i class="fa fa-arrow-left"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div v-if="solicitud.SOL_ID != '' ">
                                <label class="labels"><strong>Segundo apellido</strong></label>
                                <div v-bind:class="solicitud.SOL_SEGUNDO_APELLIDO.trim() != solicitud.SEGUNDO_APELLIDO ? 'errorNotFound' : 'dataFound'">
                                    <input type="text" class="form-control disabled text-center" v-model="solicitud.SOL_SEGUNDO_APELLIDO">
                                </div>
                            </div>
                            <div v-else class="errorNotFound">
                                <div>
                                    SIN SOLICITUD
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="labels"><strong>Fecha de nacimiento</strong> </label>
                            <label type="text" class="form-control">
                                {{ solicitud.FECHA_NACIMIENTO }}
                            </label>
                        </div>
                        <div class="col-md-2 text-center arrows">
                            <div v-if="solicitud.SOL_ID != ''  && solicitud.SOL_FECHA_NACIMIENTO.trim() != solicitud.FECHA_NACIMIENTO.trim()">  
                                <div v-if="solicitud && solicitud.SOL_FECHA_NACIMIENTO.trim() != solicitud.FECHA_NACIMIENTO.trim()">
                                    <button class="btn btn-danger" @click="i2s($event, solicitud.RIN, 'FECHA_NACIMIENTO')">
                                        <i class="fa fa-arrow-right"></i>
                                    </button>
                                    <button class="btn btn-success" @click="s2i($event, solicitud.RIN, 'FECHA_NACIMIENTO')">
                                        <i class="fa fa-arrow-left"></i>
                                    </button>
                                </div>
                            </div>  
                        </div>
                        <div class="col-md-5">
                            <div v-if="solicitud.SOL_ID != '' ">
                                <label class="labels"><strong>Fecha de Nacimiento</strong></label>
                                <div v-bind:class="solicitud.SOL_FECHA_NACIMIENTO.trim() != solicitud.FECHA_NACIMIENTO ? 'errorNotFound' : 'dataFound'">
                                    <input type="text" class="form-control disabled text-center" v-model="solicitud.SOL_FECHA_NACIMIENTO">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5"><label class="labels"><strong>Sexo</strong></label>
                            <label type="text" class="form-control">
                                {{ solicitud.SEXO }}
                            </label>

                        </div>
                        <div class="col-md-2 text-center arrows">
                            <div v-if="solicitud.SOL_ID != ''  && solicitud.SOL_SEXO.trim() != solicitud.SEXO.charAt(0)">
                                <button class="btn btn-danger" @click="i2s($event, solicitud.RIN, 'SEXO')">
                                    <i class="fa fa-arrow-right"></i>
                                </button>
                                <button class="btn btn-success" @click="s2i($event, solicitud.RIN, 'SEXO')">
                                    <i class="fa fa-arrow-left"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div v-if="solicitud.SOL_ID != '' ">
                                <label class="labels"><strong>Sexo</strong></label>
                                <div v-bind:class="solicitud.SOL_SEXO != solicitud.SEXO.charAt(0) ? 'errorNotFound' : 'dataFound'">
                                    <input type="text" class="form-control disabled text-center" v-model="solicitud.SOL_SEXO">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- OCULTO MIENTRA NO SE DEFINA ACCIÓN PARA LOS REGISTROS NO ENCOTRADOS -->
                <div class="m-5 text-right" v-if="false">
                    <button class="btn btn-primary profile-button" type="button" @click="updateRecord()"><i class="fa fa-save"></i> Actualizar</button>
                    <button class="btn btn-primary profile-button" type="button" @click="updateField()"><i class="fa fa-save"></i> Actualizar</button>
                </div>
            </div>

            <div :class="solicitud.STATUS == 1 ? 'offset-3 col-md-6' : 'col-md-6'" v-if="solicitud.SOL_ID ">
                <div :class="solicitud.STATUS == -2 ? 'mt-5 pt-5' : ''" >
                    <SolicitudArt44 :rin="solicitud.RIN" :solid="solicitud.SOL_ID"></SolicitudArt44>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SolicitudArt44 from '@/components/SolicitudArt44.vue'
import RegistroIdemia from '@/components/RegistroIdemia.vue'

export default{
    name: 'IdemiaDetails',
    props:{
        rin: String,
    },
    components:{
        SolicitudArt44,
        RegistroIdemia
    },
    data(){
        return {
            idemiaRIN: this.rin,
            solicitud:{}
        }
    },
    computed:{
        ...mapGetters(['idemiaDetails']),
        solicitudOK(){
            return this.solicitud.SOL_NOMBRES.trim() == this.solicitud.NOMBRES.trim() && 
            this.solicitud.SOL_PRIMER_APELLIDO.trim() == this.solicitud.PRIMER_APELLIDO.trim() && 
            this.solicitud.SOL_SEGUNDO_APELLIDO.trim() == this.solicitud.SEGUNDO_APELLIDO.trim()  && 
            this.solicitud.SOL_FECHA_NACIMIENTO == this.solicitud.FECHA_NACIMIENTO && 
            this.solicitud.SOL_SEXO == this.solicitud.SEXO.charAt(0);
        }
    },
    methods:{
        ...mapActions(['details', 'getDetails', 'updateSolicitud']),
        i2s(evt, rin, field){
            evt.stopPropagation();
            evt.preventDefault();
            let resp = confirm('Desea actualizar: ' + field + ' con los datos de Idemia?');
            if(resp){
                this.solicitud["SOL_" + field] = this.solicitud[field];
                this.updateSolicitud({rin:rin, sol:this.solicitud.SOL_ID, direction: 'i2s', field: field})
            }
        },
        s2i(evt, rin, field){
            evt.stopPropagation();
            evt.preventDefault();
            let resp = confirm('Desea actualizar: ' + field + ' con los datos de la Solicitud?');
            if(resp){
                this.solicitud[field] = this.solicitud["SOL_" + field];
                
                this.updateSolicitud({rin:rin, sol:this.solicitud.SOL_ID, direction: 's2i', field: field})
            }
        },
        goBack() {
            this.$router.go(-1);
        },
    },
    created(){
        this.details(true);
    },
    mounted(){
        this.getDetails(this.rin).then(() => {
            this.solicitud = this.idemiaDetails[0];
        })
    }
}
</script>