<style>
    .sol44{
        font-size: 0.9rem;
    }
    .sol44 .row p{
        border: solid 1px #aaa;
        min-height: calc(1rem + 10px);
        line-height: 1rem;
        padding:5px;
        background: #fffa;
        border-radius: 5px;
        box-shadow: 1px 1px 6px #bbb;
    }
    
    .sol44.editMode .row p{
        background: #fffa;
        color:#000;
        min-height: calc(1rem + 21px);
        line-height: 1.5rem;
        border-color: #ccc;
    }
    .sol44 .card-body{
        padding:0;
    }
    .sol44 span h6{
        font-size: 0.9rem;
        font-weight: bold;
        color:#666;
    }

    .sol44 h6{
        font-weight: bold;
        color: #000;
    }
    .sol44 .nombres{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:1rem;
    }
    div.confirm,
    div.solIsValid{
        opacity: 0;
        position: fixed;
        top: 10%;
        left:0;
        margin:0 25%;
        width: 50%;
        z-index: 1000;
        transition: all .3s;
    }
    div.confirm.show,
    div.solIsValid.show{
        opacity: 1;
        transition: all 1s;
    }
    div.solIsValid ul li{
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap:1rem;
    }
    
    div.solIsValid ul li.error{
        background: #faac;
    }

    .searchResults li.list-group-item{
        padding:0;
    }
    .searchResults li:hover{
        background:#eee;
        color:#000;
    }

    .searchResults li.selected{
        background: rgb(209 241 169);
    }
    .searchResults a{
        display: grid;
        grid-template-columns: 1.5fr 8fr 1fr;
        text-decoration: dotted;
        color:#666;
        padding: 0.5rem;
    }
    .searchResults a:hover{
        color:#000;
    }

    

</style>
<style>
  .overlay{
      position: fixed !important;
      z-index: 100;
      top:0;
      left: 0;
      width: 100%;
      height:100vh;
      background:#0006;
  }
</style>
<template>
    
    <h5 class="text-center">Búsqueda de Solicitudes en Portal Art44</h5>
    <div class="text-left">
            <button class="btn btn-secondary btn-small profile-button ml-1" @click="goBack()">
                <i class="fa fa-reply" aria-hidden="true"></i> Volver
            </button>
    </div>

    <div class="m-1 mt-4">
        <h2 class="text-left">RUN: {{ idemiaRow.RUN }}</h2>
    </div>
    <div class="row my-3" v-if="idemiaRow">
        <div class="col-md-2">
            <label class="labels">
                <strong>Fecha de enrolamiento </strong>
            </label>
            <label type="text" class="form-control">
                {{ idemiaRow ? idemiaRow.FECHA_CREACION_IDENTIDIDAD : '' }}
            </label>
        </div>
        <div class="col-md-4">
            <label class="labels">
                <strong>Nombre Completo </strong>
            </label>
            <label type="text" class="form-control">
                {{ idemiaRow ? ((idemiaRow.NOMBRES || '') + " " + (idemiaRow.PRIMER_APELLIDO || '') + " " + (idemiaRow.SEGUNDO_APELLIDO || '')) : '-' }}
            </label>
        </div>
        <div class="col-md-2">
            <label class="labels">
                <strong>Fecha de Nacimiento</strong>
            </label>
            <label type="text" class="form-control">
                {{ idemiaRow ? idemiaRow.FECHA_NACIMIENTO : '' }}
            </label>
        </div>
        <div class="col-md-2">
            <label class="labels">
                <strong>Sexo</strong>
            </label>
            <label type="text" class="form-control">
                {{ idemiaRow ? idemiaRow.SEXO : '' }}
            </label>
        </div>
    </div>
    
    <div class="searchPanel">
        <div class="row">
            <div class="col-md-4">
                <h5>Resultados</h5>
                <ul class="searchResults list-group text-left" v-if="searchResults.length > 0">
                    <li  
                    v-for="r in searchResults" :key="r.solicitud.solicitudId"
                    class="list-group-item" :class="r.solicitud.solicitudId == solID ? 'selected' : ''">
                    <a href="javascript:;" @click="showSolicitud(r.solicitud.solicitudId)">
                        <span>
                            {{ r.solicitud.solicitudId }}
                        </span> 
                        <span>{{ r.solicitud.titulo }}
                        </span>
                        <span class="badge text-bg-success">
                            {{ r.avgMatch * 100 }}%
                        </span>
                    </a>
                    </li>
                </ul>
                <div v-else>
                    
                    <div v-if="loading">
                        <div class="text-center mt-5">
                            <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Buscando Solicitudes</small></div>
                        </div>
                    </div>
                    <div v-else>
                        No se encontraron solicitudes
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div v-if="solID != -1">
                    <div class="card mx-4">
                        <div class="card-body">
                            <SolicitudArt44 :rin="idemiaRIN" :solid="solID"></SolicitudArt44>
                        </div>
                    </div>
                    
                </div>
<!--                 <SolicitudArt44 :rin="idemiaRIN" :solid="idemiaRow.SOL_ID"></SolicitudArt44> -->
            </div>
        </div>
    </div>

</template>
<script>


import { mapActions, mapGetters } from 'vuex';
import SolicitudArt44 from './SolicitudArt44.vue';
export default{
    name: 'SearchArt44',
    components:{
       SolicitudArt44
    },
    props:{
        rin: String
    },
    data(){
        return {
            loading: true,
            overlay: false,
            sols:[],
            idemiaRIN: this.rin,
            idemiaRow:{},
            searchResults: [],
            solID: -1
        }
    },
    watch:{
    },
    computed:{
        ...mapGetters(['idemiaDetails', 'getResultsNotFound'])
    },
    methods:{
        ...mapActions(['getSol44', 'getConfig', 'details', 'getDetails', 'searchNotFound']),
        
        goBack() {
            this.$router.go(-1);
        },
        showSolicitud(solId){
            this.solID = -1;
            let that = this;
            setTimeout(()=>{
                that.solID = solId;
            }, 100)
        }
    },
    created() {
        this.searchResults = []
        this.details(true);
        this.getConfig().then(()=> {
        });

    },
    mounted(){

        this.getDetails(this.rin).then(() => {
            this.idemiaRow = this.idemiaDetails[0];
            this.searchNotFound(this.idemiaRow).then( data => {
              
                this.searchResults = data;
                this.loading = false;
            })
        })
    }
}

</script>