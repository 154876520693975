<style scoped>
  div.actions ul,
  div.actions ul li{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  div.actions ul li{
    font-size: 0.8rem;
  } 
  div.container.cards{
    display: grid;
    gap: 0.5rem;
    grid-auto-flow: dense;
    grid-auto-rows: 6rem;
    grid-template-columns: repeat(4, 1fr);
  }
  div.container.cards > div{
    font-size:1rem;
    background:radial-gradient(circle, #fff, #ddd);
    border-radius:5px;
    border:solid 1px #ccc;
    box-shadow: 1px 0 8px #aaaa;
  }
  
  div.container.cards > div:hover{
    box-shadow: 0 0 10px #ccc;
    
  }
  
  div.container.cards > div h1{
    font-size: 1.75rem;
    font-weight: bold;
  }
  div.container.cards > div a{
    text-decoration: none;
    color: #666;
    display: block;
    padding: 0.5rem 0;
    font-size: 80%;
  }

  div.container.cards > div a:hover{
    color: #000;
  } 

  div.timelime{
    margin:1rem 3rem;

  }
/* 
  @media only screen and (max-width: 767px) {
    div.topPanel, div.cards{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 1rem;
        grid-row: none;
        padding:0;
    }

    .card a{
      display:block;
      margin: 0;
      padding:0;
    }

  }
 */
.lastRunData ul{
  padding:0;
  margin:0;
  display: grid;
  gap:1rem;
  grid-auto-rows: 1rem;
  grid-template-columns: 2fr repeat(4, 1fr);
}
.lastRunData ul li{
    list-style: none;
  text-align: left;
  padding:0;
  margin:0;
  line-height: 1rem;
}


.lastRun ul li strong{
  display: inline-block;
  text-align:center;
  width: 2rem;
}
.container.cards .datesWidget{
  background: none;
  border:none;
  box-shadow: none;
}

.container.cards .datesWidget:hover{
  box-shadow: none;
}
</style>

<template>
    <div>
      <div class="topPanel" v-if="!loading">        
        <div class="row">
          
          <div class="col-md-8">
            <div class="container cards">
              <div class="">
                <RouterLink to="/exitosos" >
                  <h1>{{ stats.ok }}</h1>  
                  <p>Enrolamientos Exitosos</p>
                </RouterLink>
              </div>
              <div class="">
                <RouterLink to="/noexitosos" >
                  <h1>{{ stats.nok }}</h1>  
                  <p>Excepciones Tandem</p>
                </RouterLink>
              </div>
              <div class="">
                
                <RouterLink to="/rechazados" >
                  <h1>{{ stats.rejected }}</h1>  
                  <p>Solicitudes Rechazadas</p>
                </RouterLink>
              </div>
              <div class="">
                
                <RouterLink to="/notfound" >
                  <h1>{{ stats.notfound }}</h1>  
                  <p>Solicitudes no Encontradas</p>
                </RouterLink>
              </div>
              
            </div>
            <div class="monthChart m-3" ref="monthChart">
              <div v-if="timeline.length > 0">
                <highcharts :options="monthOptions"></highcharts>
              </div>
              <div v-else class="text-center mt-5">
                  <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
              </div>
            </div>
          </div>
          <div class="col-md-4 pt-3">
            <div v-if="drawByOrg" class="mt-5 pt-5">
              <highcharts :options="pieChartOptions"></highcharts>
            </div>
            <div v-else class="text-center mt-5">
                  <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
              </div>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-light btn-sm" @click="reporteSemanal()"><i class=" fas fa-file-download" ></i> Descargar Resumen</button>
  
        </div>
        <hr />
      </div>

      <div v-if="timeline.length > 0">  
        <div class="timeline m-3" ref="timeline">
          <div class="text-center mt-5">
            <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
          </div>
        </div>
      </div>

    </div>
</template> 

<script>
import Highcharts from 'highcharts'

import HighchartsTimeline from 'highcharts/modules/timeline';

import { mapGetters , mapActions} from 'vuex';


HighchartsTimeline(Highcharts);

export default{
  data() {
    return {
      loading: true ,
      drawByOrg: false,
      drawMonth: false,
      drawTimeline: false,
      fechaDetalle: '',
      lastRun: {
        date:'',
        ok: '-',
        nok: '-',
        notfound: '-',
        rechazos: '-'
      },
      monthOptions: {},
      pieChartOptions: {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
          
        },
        credits: {
          enabled: false
        },
        title: {
            text: "Solicitudes por Organización"
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Solicitudes',
            colorByPoint: true,
            data: []
        }]
      },
      chartColors: [
        "#6a68",
        '#66a8'
      ]
    }
  },
  computed: {
    ...mapGetters(['stats', 'timeline', 'loggedIn', 'lastProcess'])
  },
  methods:{
    ...mapActions(['details', 'getReporteSemanal', 'updateTimeline', 'updateStats', 'getLastProcess']),
    reporteSemanal(){
      
      this.getReporteSemanal().then(csvText => {
      
        const blob = new Blob([csvText], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'reporte_semanal.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
    },
    showMonthChart(){
      let timelineData = this.timeline.map(item => [new Date(item.fecha).toJSON().split("T")[0].substring(0,7) + '-01', item.total]);
      
       
      let data = timelineData;
      const transformedData = data.reduce((result, [date, value]) => {
        if (!result[date]) {
          result[date] = 0;
        }
        result[date] += value;
        return result;
      }, {});
      
    let rr = [ ];
      Object.keys(transformedData).forEach(t => {
     
      rr.push([new Date(t).getTime() , transformedData[t]])
    })
    
    this.monthOptions = {
      colors: this.chartColors,
          charts:{
            type: 'timeline'
          },
          accessibility:{
            enabled: false
          },
          title: {
            text: 'Enrolamientos Mensuales Art44'
          },
          xAxis: {
            type: 'datetime'
          },
          yAxis: [
            {
              title: {
                text: "Enrolamientos Mensuales",
              },
              labels: {
                format: "{value}",
              },
            }
          ],
          series: [{
            name: 'Total' ,
            data: rr,
            yAxis: 0, // use the first y-axis for this series
            type: "column",
            point: {
                events: {
                    click: function(item) {
                      
                      this.fechaDetalle = item.fecha;
                        console.log ('Fecha: '+ this.category + ', Cantidad: '+ this.y);
                    }
                }
            }
          }],
          credits: {
            enabled: false
          },
          exporting: {
            enabled: true
          }
        };

    },
    showTimeline(){
      
    let that = this;
      
      setTimeout(() => {
        let chartContainer = this.$refs.timeline;
        
        const options = {
          colors: that.chartColors,
          charts:{
            type: 'timeline'
          },
          accessibility:{
            enabled: false
          },
          title: {
            text: 'Evolución de Enrolamientos Art44'
          },
          xAxis: {
            type: 'datetime'
          },
          yAxis: [
            {
              title: {
                text: "Enrolamientos Diarios",
              },
              labels: {
                format: "{value}",
              },
            },
            {
              title: {
                text: "Acumulado",
              },
              min: 0,
              labels: {
                format: "{value}",
              },
              opposite: true, // place this axis on the right side of the chart
            },
          ],
          series: [{
            name: 'Enrolamientos Diarios',
            data: that.timeline.map(item => [new Date(item.fecha).getTime(), item.total]),
            yAxis: 0, // use the first y-axis for this series
            type: "area",
            point: {
                events: {
                    click: function(item) {
                      
                      that.fechaDetalle = item.fecha;
                        console.log ('Category: '+ this.category + ', value: '+ this.y);
                    }
                }
            }
          }, 
          {
            name: 'Acumulado a la fecha',
            data: that.timeline.map(item => [new Date(item.fecha).getTime(), item.sum]),
            yAxis: 1, // use the second y-axis for this series
            type: "spline",
            /* point: {
                events: {
                    click: function() {
                        alert ('Category: '+ this.category +', value: '+ this.y);
                    }
                }
            } */
          }],
          credits: {
            enabled: false
          },
          exporting: {
            enabled: true
          }
        };
        

          Highcharts.chart(chartContainer, options);
          
        that.loading = false; 
      }, 100);
    }
  },
  watch: {
    lastProcess(newv){
      if(newv[0]){

        this.lastRun.date = newv[0].FECHA_CREACION_IDENTIDIDAD;
        this.lastRun.ok = newv.find(l => l.STATUS == 1) ? newv.find(l => l.STATUS == 1).TOTAL : 0;
        this.lastRun.nok = newv.find(l => l.STATUS == -1) ? newv.find(l => l.STATUS == -1).TOTAL : 0;
        this.lastRun.notfound = newv.find(l => l.STATUS == -2) ? newv.find(l => l.STATUS == -2).TOTAL : 0;
        this.lastRun.rechazos = newv.find(l => l.STATUS == -3) ? newv.find(l => l.STATUS == -3).TOTAL : 0;
      }
    },
    timeline(){
      this.pieChartOptions.series[0].data = this.stats.byOrg;
      this.drawByOrg = true;
      this.showMonthChart();
      this.showTimeline();
    },
  },
  created(){
    if (this.loggedIn) {
      this.getLastProcess();
      let tstats = sessionStorage.getItem("stats");
      if(tstats == null){
        this.updateStats().then(() => { 
          this.showMonthChart();
        })
      } else {
        this.showMonthChart();
        this.showTimeline()
      }

      let tline = sessionStorage.getItem("timeline");
      if(tline == null){
        this.updateTimeline();
        this.showMonthChart();
      } else {
        this.pieChartOptions.series[0].data = this.stats.byOrg;
        this.drawByOrg = true;
      }
    }    
  },
  mounted() {
    this.details(false);
    this.loading = false;
  }
}
</script>

