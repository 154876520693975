<template>
    <div class="mx-3 my-2 text-left">
        <div class="row">
            <div class="col-md-9">
                <h2><i class="fas fa-question"></i> Solicitudes no Encontradas</h2>
                <blockquote>Registros no encontrados en el portal de solicitudes de Artículo 44</blockquote>
               
            </div>
            <div class="col-md-3 mt-5 text-right">
                <button class="btn btn-sm btn-primary" @click="generateFile()" v-if="idemiaNotFoundRows.length > 0">
                    <i class="fas fa-file-download donwloadIcon"></i> Descargar Archivo
                </button>
            </div>
        </div>
        <IdemiaTable :pType="pageType"  v-if="!loading && idemiaNotFoundRows.length > 0"></IdemiaTable>
        <div class="text-center mt-5" v-else>
            <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
        </div>
       
        <!-- <IdemiaNOTFOUND></IdemiaNOTFOUND> -->
        
    </div>
</template> 

<script>
//import IdemiaNOTFOUND from '@/components/IdemiaNOTFOUND.vue';
import IdemiaTable from '@/components/IdemiaTable.vue'
import xlsx from "xlsx/dist/xlsx.full.min";
import { mapActions, mapGetters } from 'vuex';
export default {

    components: {
       // IdemiaNOTFOUND,
        IdemiaTable
    },
    data(){
        return {
            loading: false,
            pageType: 'NotFound'
        }
    },
    computed: {
        ...mapGetters(['idemiaNotFoundRows'])
    },
    methods: {
        ...mapActions(['details', 'updateIdemiaNotFound']),
        generateFile() {
            let data = this.idemiaNotFoundRows;
            
            const XLSX = xlsx;
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet);
            XLSX.writeFile(workbook, "CasosNoEncontrados_" + (new Date().valueOf()) + ".xlsx");
        }
    },
    created(){
        if(this.idemiaNotFoundRows.length == 0){
            this.updateIdemiaNotFound(); 
        }
    },
    mounted() {
        this.details(false);
        this.loading = false;
    
    }
}
</script>