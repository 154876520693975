<template>
    <div class="main mt-5 mx-auto">
        <input type="checkbox" id="chk" aria-hidden="true">

        <div class="signup">
            <form>
                <h3 for="chk" aria-hidden="true" class="mt-5">Artículo 44</h3>
                <p>Gestión Identificación</p>
                <img src="@/assets/logo-registro-civil.jpg" />
            </form>
        </div>

        <div class="login">
            <form @submit.prevent="login($event)">
                <label for="chk" aria-hidden="true">Login</label>
                <input type="text" name="username" v-model="user.username" placeholder="Usuario" required
                    class="form-control" @blur="validRut" />
                <input type="password" name="password" v-model="user.password" placeholder="Contraseña" required=""
                    class="form-control">
                <button v-if="!loading">Login</button>
               <p v-else >Validando credenciales...</p>
            </form>
            <Transition name="bounce">
                <h4 v-if="showNoAuth" class="noAuth mt-4"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    {{ showNoAuth }}
                </h4>
            </Transition>
        </div>
    </div>
</template> 

<script>
//Import Actions
import { mapActions } from 'vuex';
import { validate, format } from 'rut.js';

export default {
    name: 'LoginPage',
    data() {
        return {
            user: {
                username: '',
                password: ''
            },
            showNoAuth: '',
            loading: false
        }
    },
    methods: {
        ...mapActions(['loginUser']),

        validRut(evt) {
            let rut = evt.target.value.trim();
            if (validate(rut)) {
                rut = format(rut, { dots: false });
                this.user.username = rut;
                this.showNoAuth = '';
            } else {
                this.showNoAuth = "Rut Inválido";
            }
        },

        login(evt) {
            this.loading = true;
            this.showNoAuth = '';       
            evt.preventDefault();

            if (this.user.username != '' && this.user.password != '') {

                this.loginUser(this.user).then(res => {
                    
                    if (res) {
                        this.$router.push("/")
                    } else {
                        this.showNoAuth = "Credenciales Inválidas";
                        this.loading = false;
                    }
                })

            }
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
}

.main {

    width: 350px;
    height: 500px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 5px 20px 50px #000;
}

#chk {
    display: none;
}

.signup {
    position: relative;
    width: 100%;
    height: 100%;
}

label {
    color: black;
    font-size: 2.3em;
    justify-content: center;
    display: flex;
    margin: 60px 60px 20px 60px;
    font-weight: bold;
    cursor: pointer;
    transition: .5s ease-in-out;
}

input {
    width: 60%;
    height: 40px;
    background: #e0dede;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}

button {
    width: 60%;
    height: 40px;
    margin: 10px auto;
    justify-content: center;
    display: block;
    color: #fff;
    background: #0077c2;
    font-size: 1em;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: .2s ease-in;
    cursor: pointer;
}

button:hover {
    background: #005ea6;
}

.login {
    height: 460px;
    background: #eee;
    border-radius: 60% / 10%;
    transform: translateY(-180px);
    transition: .2s ease-in-out;
}

.login label {
    color: #0077c2;
    transform: scale(.6);
}

#chk:checked~.login {
    transform: translateY(-400px);
}

#chk:checked~.login label {
    transform: scale(1);
}

#chk:checked~.signup h3 {
    display: none;
    transform: scale(.6);
}

#chk:checked~.signup p {
    display: none;
}

#chk:checked~.signup img {
    margin-top: 2rem;
    width: 30%;
    height: auto;
}

img {
    width: 50%;
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}

.noAuth {
    color: red;
}
</style>
