<template>
  <div class="mx-3 my-2 text-left">
    <div class="row">
      <div class="col-md-9">
        <h2><i class="fas fa-thumbs-down fa-flip-horizontal"></i>Enrolamientos Rechazados</h2>
        <blockquote>Registros rechazados en erolamiento con Idemia</blockquote>
      </div>
      <div class="col-md-3 mt-5 text-right">
        <button class="btn btn-primary btn-sm" @click="generateFile()"  v-if="idemiaRechazadosRows.length > 0">
          <i class="fas fa-file-download"></i> Descargar Archivo
        </button>
      </div>
    </div>
    <div class="center grid">
      <IdemiaTable :pType="pageType"  v-if="!loading && idemiaRechazadosRows.length > 0"></IdemiaTable>
      <div class="text-center mt-5" v-else>
            <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
        </div>
    </div>
   <!--  <IdemiaRechazos></IdemiaRechazos> -->
  </div>
</template> 

<script>
import IdemiaTable from '@/components/IdemiaTable.vue'
import xlsx from "xlsx/dist/xlsx.full.min";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    /* IdemiaRechazos, */
    IdemiaTable
  },
  data(){
    return {
      loading: true,
      pageType: 'Rechazados'
    }
  },
  computed: {
        ...mapGetters(['idemiaRechazadosRows'])
    },
  methods: {
    ...mapActions(['details', 'updateIdemiaRechazados']),
    generateFile() {
            let data = this.idemiaRechazadosRows;
            
            const XLSX = xlsx;
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet);
            XLSX.writeFile(workbook, "CasosRechazados_" + (new Date().valueOf()) + ".xlsx");
        }

  },
  created() {
    if(this.idemiaRechazadosRows.length == 0){
      this.updateIdemiaRechazados()
    }
  },
  mounted() {
    this.details(false);
    this.loading = false;
  }
}

</script>

<style scoped></style>