
<style scoped>
div.topHeader {
  display: block;
  padding: 0 0.5rem;
  height: 6rem;
  margin-bottom: 1rem;
}
div.topHeader > div{
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
}

div.topHeader h2 {
  line-height: 5rem;
  font-weight: bold;
}

.logo {
  max-height: 5rem;
  margin: 0 2rem;
  vertical-align: middle;
  float: left;
}

.links {
  display: flex;
  /* To make the links display in a row */
  justify-content: left;
  /* To center the links */
  background-color: #f2f2f2;
  /* Background color */
  padding: 1rem 0 0 1rem;
  font-size: 90%;
}

.links a {
  border: 1px solid #ccc;
  /* Border around the links */
  border-top-left-radius: 5px;
  /* Rounded corners for the first link */
  border-top-right-radius: 5px;
  /* Rounded corners for the last link */
  padding: 10px 20px;
  /* Padding inside the links */
  margin: 0;
  /* Margin between the links */
  text-decoration: none;
  /* Remove underline */
  color: #888;
  /* Text color */
}

.links a:hover {
  background-color: #ddd;
  /* Background color on hover */
}

.links a.link-active {
  background-color: #fff;
  /* Background color on active link */
  border-bottom-color: #fff;
  /* Remove bottom border on active link */
  color: #000;
  font-weight: bold;
  ;
}


@media only screen and (max-width: 767px) {

  div.topHeader {
    display: block;
    padding: 0 0.2rem;
    height: 4rem;
    margin-bottom: 1rem;
  }
  .logo {
      max-height: 3rem;
      margin: 0;
      vertical-align: middle;
      float: left;
  }
  h2{
    margin:0;
    padding:0;
    font-stretch: condensed;
  }

  a span{
    display: none;
  }

}
</style>
<template>
  <div class="topHeader" v-if="loggedIn">
    <div>
      <img class="logo" src="@/assets/logo.png" alt="logo" />
      <h2>Gestión Artículo 44</h2>
      <ProfilePanel></ProfilePanel>
    </div>
  </div>
  
  <!-- Ocultamos en modo pantalla completa -->
  <div class="links" v-if="!showDetails && loggedIn" >
    <RouterLink @click="trackPage($event)" ref="home" to="/" active-class="link-active"><i class="fas fa-chart-line"></i> <span>Resumen</span></RouterLink>
    <RouterLink @click="trackPage($event)" ref="exitosos" to="/exitosos" active-class="link-active"><i class="fas fa-check"></i> <span>Exitosos</span> <span class="badge text-bg-success">{{ lastRun.ok }}</span></RouterLink>
    <RouterLink @click="trackPage($event)" ref="noexitosos" to="/noexitosos" active-class="link-active"><i class="fas fa-exclamation"></i> <span>No Exitosos</span> <span class="badge text-bg-danger">{{ lastRun.nok }}</span></RouterLink>
    <RouterLink @click="trackPage($event)" ref="rechazados" to="/rechazados" active-class="link-active"><i class="fas fa-thumbs-down fa-flip-horizontal"></i> <span>Rechazados</span> <span class="badge text-bg-warning">{{ lastRun.rechazos }}</span></RouterLink>
    <RouterLink @click="trackPage($event)" ref="notfound" active-class="link-active" to="/notfound"><i class="fas fa-question"></i> <span>No Encontrados</span> <span class="badge text-bg-info">{{ lastRun.notfound }}</span></RouterLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProfilePanel from '@/components/ProfilePanel.vue';

export default {
  data(){
    return {
      lastRun: {
        date:'',
        ok: '-',
        nok: '-',
        notfound: '-',
        rechazos: '-'
      }
    }
  },
  watch: {
    lastProcess(newv){
      if(newv[0]){

        this.lastRun.date = newv[0].FECHA_CREACION_IDENTIDIDAD;
        this.lastRun.ok = newv.find(l => l.STATUS == 1) ? newv.find(l => l.STATUS == 1).TOTAL : 0;
        this.lastRun.nok = newv.find(l => l.STATUS == -1) ? newv.find(l => l.STATUS == -1).TOTAL : 0;
        this.lastRun.notfound = newv.find(l => l.STATUS == -2) ? newv.find(l => l.STATUS == -2).TOTAL : 0;
        this.lastRun.rechazos = newv.find(l => l.STATUS == -3) ? newv.find(l => l.STATUS == -3).TOTAL : 0;
      }
    }
  },
  components:{
    ProfilePanel
  },
  computed: {
    ...mapGetters(['showDetails', 'loggedIn', 'lastProcess'])
  },
  methods:{
    trackPage(evt){

      let elm = evt.target.tagName == "SPAN" ? evt.target.parentNode : evt.target;
      sessionStorage.setItem("lastPage", elm.getAttribute("href")); 
    }
  },
  mounted() {
    this.lastRun = this.lastProcess;
    let that = this;
    let lp = sessionStorage.getItem("lastPage");
    //if(!lp || lp == "/"){
      try{

        if(lp && lp != "/"){
          lp = lp.replace("/", "");

          setTimeout(() => {
            that.$refs[lp].$el.click();
            
          }, 300)
          
        }
      }catch(ex){
        console.log(ex);
      }
  }
}

</script>
