<style>
        .idemiaRow{
            font-size: 0.9rem;
        }
        .idemiaRow .row p{
            border: solid 1px #aa9;
            min-height: calc(1rem + 10px);
            line-height: 1rem;
            padding:5px;
            background: #fffa;
            border-radius: 4px;
            box-shadow: 1px 1px 6px #bb9;
        }

        .idemiaRow.rechazado .row p{
            border: solid 1px #eaa; 
            box-shadow: 1px 1px 6px #ebb;
        }
        .idemiaRow .card-body{
            padding:0;
        }
        .idemiaRow h6{
            font-size: 0.9rem;
            font-weight: bold;
            color:#666;
        }

</style>
<template>
    <div class="idemiaRow text-left mt-2" :class="idemiaRow.STATUS == -3 ? 'rechazado' : ''">
        
        <h3>{{ STATUS_TEXT }}</h3>
        <h4>{{ idemiaRow.RUN == 'UNKNOWN' ? idemiaRow.RUN_CANDIDATO : idemiaRow.RUN }}</h4>
        <h5 class="text-center">Identificador Idemia <strong>{{ rin }}</strong></h5>
        <div class="card  alert" :class="idemiaRow.STATUS == -3 ? 'alert-danger' : 'alert-warning'">
            <div class="card-body">
                <div class="row ">
                    <div class="col-12 mb-1">
                        <h6>Nombres</h6>
                        <p class="text-muted">{{ idemiaRow.NOMBRES + " " + idemiaRow.PRIMER_APELLIDO + " " + idemiaRow.SEGUNDO_APELLIDO }}</p>
                    </div>
                    <div class="col-6 mb-1">
                        <h6>Sexo</h6>
                        <p class="text-muted">{{ idemiaRow.SEXO == 'M' ? 'Masculino' : (idemiaRow.SEXO== 'F' ? 'Femenino' : idemiaRow.SEXO) }}</p>
                    </div>
                    <div class="col-6 mb-1">
                        <h6>Fecha Nacimiento</h6>
                        <p class="text-muted">{{ idemiaRow.FECHA_NACIMIENTO }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mb-1">
                        <h6>Fecha Solicitud</h6>
                        <p class="text-muted">{{ idemiaRow.FECHA_SOLICITUD }}</p>
                    </div>
                    <div class="col-6 mb-1">
                        <h6>Fecha Rechazo</h6>
                        <p class="text-muted">{{ idemiaRow.FECHA_RECHAZO }}</p>
                    </div>
                </div>
                <div class="row" v-if="idemiaRow.RESPONSE_TANDEM ">
                    <div class="col-12 mb-1">
                        <h6>Mensaje TANDEM</h6>
                        <p class="text-muted">{{ idemiaRow.RESPONSE_TANDEM }}</p>
                    </div>
                </div>
            
                <div class="row">
                    <div class="col-6 mb-1" v-if="idemiaRow.STATUS == -3">
                        <h6>Motivo Rechazo</h6>
                        <p class="text-muted">RUN existente</p>
                    </div>
                    <div class="col-6 mb-1" v-if="idemiaRow.STATUS == -3">
                        <h6>RUN Candidato</h6>
                        <p class="text-muted">{{ idemiaRow.RUN_CANDIDATO }}</p>
                    </div>
                </div>
                <div v-if="loading" >
                    <div class="text-center mt-5">
                        <img src="@/assets/loading.gif" /><br /><div class="mt-1"><small>Cargando</small></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {  mapGetters } from 'vuex';

export default{
    name: 'RegistroIdemia',
    props:{
        rin: String
    },
    data(){
        return {
            idemiaRow:{}
        }
    },
    computed:{
        ...mapGetters(['idemiaRowDetails', 'idemiaDetails']),
        STATUS_TEXT(){
            let out = "";
            switch (this.idemiaRow.STATUS) {
                case 1:
                    out =  "Sincronización Exitosa con Tandem"
                    break;
        
                case -1:
                    out =  "Error de sincronización con Tandem"
                    break;
        
                case -2:
                    out =  "No se encontró un match con una solicitud"
                    break;
                case -3:
                    out =  "Solicitud Rechazada"
                    break;
            
                default:
                    out = this.idemiaRow.STATUS;
                    break;
            }

            return out;
        }
    },
    mounted(){
        this.idemiaRow = this.idemiaDetails[0];
    }
}

</script>